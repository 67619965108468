import { useEffect, useMemo, useState } from "react";
import Table from "components/Table";
import Text from "./components/Textarea/Text";
import { useParams } from "react-router-dom";
import DataLoaderOverlay from "components/DataLoaderOverlay";
import { useReportRecords } from "hooks/useReportRecords";
import { RecordsData } from "state/records/types";
import Button from "components/Button";
import { useUser } from "state/user/hooks";
import { useNavigate } from "react-router-dom";
import SubLevelVideo from "./components/Textarea/SubVideo";
import { Link } from "react-router-dom";
import { getNextSubLevel, getPreviousSubLevel } from "constants/categoryOrdering";
import {
  isSinglePageCategory,
  points,
  pointsColor,
  pointsSubLevelColor,
  findVideoLinkByName,
} from "utils/categoriesDetail";
const CategoriesList = () => {
  const { id, category, sub_category } = useParams();
  const [prevLink, setPrevLink] = useState<any>(null)
  const [nextLink, setNextLink] = useState<any>(null)
  const navigate = useNavigate();
  const { reportData, fetchReportRecords, isLoading, updateRecordNote } =
    useReportRecords();
  const categoryData = useMemo(
    () => reportData[category!][sub_category!],
    [category, reportData, sub_category]
  );
  const categoryDataTest = useMemo(
    () => reportData[category!],
    [category, reportData]
  );
  useEffect(() => {
    setNextLink(getNextSubLevel(sub_category!, id))
    setPrevLink(getPreviousSubLevel(sub_category!, id))
  }, [sub_category])
  const { user } = useUser();
  const getTableData = useMemo(
    () => (records: RecordsData[]) => {
      return records.map((record) => ({
        value: record.value,
        gene: record.gene,
        rsID: record.rsID,
        genotype: record.genotype,
        indication: record.expanded_coaching_indication,
        genotype_note:
          record.genotype_note && record.genotype_note[record.genotype],
      }));
    },
    []
  );
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!id || !sub_category) return;
    updateRecordNote(id, sub_category, notesValue, category!);
  };
  const handleSubmitSubLevel = (
    e: React.FormEvent<HTMLFormElement>,
    subLevelKey: string,
    categoryName: string

  ) => {
    e.preventDefault();
    if (!id || !sub_category) return;
    updateRecordNote(id, categoryName, subLevelNotes[subLevelKey], category!);
  };
  const updateSubLevelNote = (subLevelKey: string, newValue: string) => {
    setSubLevelNotes((prevNotes) => ({
      ...prevNotes,
      [subLevelKey]: newValue,
    }));
  };
  const CategoryData = isSinglePageCategory(category!)
    ? categoryDataTest
    : categoryData;
  const [notesValue, setNotesValue] = useState("");
  const [subLevelNotes, setSubLevelNotes] = useState<{
    [key: string]: string;
  }>({});
  // in case of directly calling this page
  useEffect(() => {
    if (Object.keys(reportData).length === 0) {
      fetchReportRecords(id!);
    }
  }, [id, fetchReportRecords, reportData]);
  useEffect(() => {
    setNotesValue(categoryData.note)
  }, [categoryData])
  return (
    <DataLoaderOverlay isLoading={isLoading}>
      <div className="w-full p-6 bg-main sm:px-20">
        <div className="mb-8">
          <p
            className="cursor-pointer font-bold hover:underline"
            onClick={() => navigate(`/categories/${id}`)}
          >
            &#60; Menu
          </p>
        </div>
        <div className={`flex ${!!prevLink ? 'justify-between' : 'justify-end'} my-5`}>
          {!!prevLink &&
            <Button title={"< Back"}
              className="leading-normal max-w-[200px] bg-[#fffbfb] text-black p-2"
              type="button" onClick={() => navigate(prevLink)} />
          }
          {!!nextLink &&
            <Button title={"Next >"}
              className="leading-normal max-w-[200px] bg-[#fffbfb] text-black p-2"
              type="button" onClick={() => navigate(nextLink)} />
          }
        </div>
        <div className="flex gap-x-5 items-end mb-[50px]">
          <h2 className="text-xl font-normal">{sub_category}</h2>
          <p className={`${pointsColor(parseFloat(points(categoryData)).toFixed(0), sub_category)} font-semibold font-helvetica text-xl`}>
            {parseFloat(points(categoryData)).toFixed(0)}%
          </p>
        </div>
        {!isSinglePageCategory(category!) &&
          CategoryData &&
          Object.entries(CategoryData.sub_level).map(
            ([subLevelKey, record]: any) => {
              if (typeof record === "object" && record !== null) {
                const videoLink = findVideoLinkByName(subLevelKey);
                return (
                  <div key={subLevelKey} className="mb-3">
                    <h5 className="font-bold">{subLevelKey} <span className={`${pointsSubLevelColor(parseFloat(((record.total_risk_value / record.total) * 100).toString()).toFixed(0), subLevelKey)} font-semibold ml-3`}>{parseFloat(((record.total_risk_value / record.total) * 100).toString()).toFixed(0)}%</span></h5>
                    {videoLink && <SubLevelVideo src={videoLink} />}
                    <Text text={record.description} />
                    <Table data={getTableData(record.records)} title={subLevelKey} />
                  </div>
                );
              }
              return null;
            }
          )}
        {isSinglePageCategory(category!) &&
          Object.entries(CategoryData).map(([categoryName, categoryData]) => {

            return (
              <div key={categoryName} className="mb-3 flex-col-reverse">
                {Object.entries(categoryData.sub_level).map(
                  ([subLevelKey, record]: any) => {
                    if (typeof record === "object" && record !== null) {
                      const videoLink = findVideoLinkByName(subLevelKey);

                      return (
                        <div key={subLevelKey} className="mb-3">
                          <h5 className="font-bold">{subLevelKey} <span className={`${pointsSubLevelColor(parseFloat(((record.total_risk_value / record.total) * 100).toString()).toFixed(0), subLevelKey)} font-semibold ml-3`}>{parseFloat(((record.total_risk_value / record.total) * 100).toString()).toFixed(0)}%</span></h5>
                          {videoLink && <SubLevelVideo src={videoLink} />}
                          <Text text={record.description} />
                          <Table data={getTableData(record.records)} title={subLevelKey} />
                        </div>
                      );
                    }
                    return null;
                  }
                )}
              </div>
            );
          })}
        <div>
          <label className="text-xl font-bold font-helvetica mb-3">
            Notes
          </label>
          <form onSubmit={handleSubmit}>
            <textarea
              className="bg-[#FCFCFC] w-full h-[300px] my-5 p-4"
              value={notesValue}
              onChange={(e) => setNotesValue(e.target.value)}
              required
              readOnly={user?.isClient}
            />
            <div className="text-center">
              {!user?.isClient && (
                <Button
                  title="Save"
                  className="text-xl leading-normal max-w-[200px] bg-[#fffbfb] text-black p-3 disabled:bg-gray-400 disabled:cursor-not-allowed"
                  type="submit"
                />
              )}
            </div>
          </form>
        </div>
        <div className={`flex ${!!prevLink ? 'justify-between' : 'justify-end'} mt-5`}>
          {!!prevLink &&
            <Button title={"< Back"}
              className="leading-normal max-w-[200px] bg-[#fffbfb] text-black p-2"
              type="button" onClick={() => navigate(prevLink)} />
          }
          {!!nextLink &&
            <Button title={"Next >"}
              className="leading-normal max-w-[200px] bg-[#fffbfb] text-black p-2"
              type="button" onClick={() => navigate(nextLink)} />
          }
        </div>
      </div>
    </DataLoaderOverlay>
  );
};
export default CategoriesList;
