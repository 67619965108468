import React, { Fragment } from "react";
import { useTable, useGlobalFilter } from "react-table";
import search from "assets/Icons/search.png";
import update from "assets/Icons/update.png";
import { Link } from "react-router-dom";
import { CLIENTTABLECOLUMN as columns } from "constants/index";
import { useModal } from "context/ModalProvider";
import { useUser } from "state/user/hooks";
interface IProps {
  data: any;
  showUpdateColumn?: boolean;
  selectedClient?: string;
  setSelectedClient: (payload: string | undefined) => void;
}

const ReportTable: React.FC<IProps> = ({
  data,
  showUpdateColumn,
  selectedClient,
  setSelectedClient,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );
  const { globalFilter } = state;
  const { handleOpen } = useModal();
  const { user } = useUser();
  const client = user?.isClient
  const clientName = user?.fname + " " + user?.lname
  return (
    <Fragment>
      <div className="flex items-center w-full bg-[#FCFCFC] py-2 mb-4 w-full sm:w-9/12 mx-auto">
        <img src={search} alt="Search" className="w-8 h-8 mx-4" />
        <input
          value={globalFilter || ""}
          onChange={(e: any) => setGlobalFilter(e.target.value)}
          placeholder="Search by client name"
          className="px-3 py-2 focus:outline-none w-full font-helvetica"
        />
      </div>
      <div className="overflow-x-auto w-full sm:w-9/12 mx-auto">
        <table
          {...getTableProps()}
          className="table-auto font-sans mt-4 mb-4 w-full justify-between "
        >
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, columnIndex: number) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`border-gray-400 border-b pl-14 py-6 bg-[#FCFCFC] mobile:text-[20px] text-[25px] font-normal italic text-left ${
                      columnIndex === headerGroup.headers.length - 1 && "hidden"
                    }`}
                  >
                    {" "}
                    {!client && showUpdateColumn ? (
                      selectedClient
                    ) : client ? (
                      <p className="text-base">{clientName}</p>
                    ) : (
                      column.render("Header")
                    )}
                  </th>
                ))}
                {showUpdateColumn && !client && (
                  <th
                    className={`${"block"} border-b border-b-gray-400 px-4 pt-6  pb-[1.55rem] bg-[#FCFCFC]  mobile:text-[20px] text-[25px] italic font-normal text-center`}
                  >
                    Action
                  </th>
                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any, index: number) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any, cellIndex: number) => (
                  <td
                      {...cell.getCellProps()}
                      className={`border-b border-b-[#898989] pl-14 py-3  bg-[#FCFCFC] w-[700px] text-left text-[16px] font-helvetica  ${
                        cellIndex === row.cells.length - 1 && "hidden"
                      }`}
                    > 
                      <Link
                        to={`${
                          showUpdateColumn
                            ? "/categories/" + row.values.col2
                            : row.values.col2
                        }`}
                        onClick={() => {
                          setSelectedClient(row.values.col1);
                        }}
                      >
                        {cell.render("Cell")}
                      </Link>
                    </td>
                  ))}
                  {showUpdateColumn && !client && (
                    <td
                      className={`border-b border-b-[#898989] px-4 py-3 pt-3 bg-[#FCFCFC] text-center ${"block"}`}
                    >
                      <button
                        onClick={() => {
                          handleOpen(row.values.col2);
                        }}
                      >
                        <img src={update} className="w-5 h-5" alt="Update" />
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        {rows.length === 0 && (
          <h3 className="w-full text-xl font-normal font-sans text-[#4d4d4d]  text-[24px] text-primary mt-4 text-center">
            No search result{" "}
          </h3>
        )}
      </div>
    </Fragment>
  );
};

export default ReportTable;
