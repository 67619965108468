import { object, string, ref, number } from "yup";
export const loginSchema = object({
  email: string().required("Username is Required"),
  password: string().required("Password is Required"),
});
export const transactionSchema = object({
  cardName: string().required("CardName is Required"),
  cardNumber: string()
    .required("Card Number is Required")
    .test("cardNumber", "Card Number must be exactly 12 digits", (value) => {
      if (!value) return false;
      const cardNumberString = value.toString();
      return cardNumberString.length === 12;
    }),
  cvv: string()
    .required("CVV is Required")
    .test("cvv", "CVV must be exactly 3 digits", (value) => {
      if (!value) return false;
      const cvvString = value.toString();
      return cvvString.length === 3;
    }),
  expiryDate: string().required("Expiry Date is Required"),
});
export const fileUploadSchema = object({
  inputFile: string().required("File is Required"),
  clientName: string().required("Client Name is Required"),
  clientEmail: string()
    .email("Invalid email")
    .required("Client Email is Required"),
  serviceType: string().required("Service Type is Required"),
});
export const labFileUploadSchema = object({
  inputFile: string().required("File is Required"),
});
export const kitUpdateploadSchema = object({
  client_fname: string().required("First Name is Required"),
  client_lname: string().required("Last Name is Required"),
  client_email: string()
    .email("Invalid email")
    .required("Client Email is Required"),
     client_kit_no: string().required("Kit number is Required"),
});
export const forgetPasswordSchema = object({
  email: string().email("Invalid email").required("Client Email is Required"),
});
export const resetPasswordSchema = object({
  new_password1: string().required("Password is required"),
  new_password2: string()
    .oneOf([ref("new_password1")], "Passwords must match")
    .required("Confirm password is required"),
});

export const checkoutSchema = object({
  clientEmail:string().email("Invalid email").required("Client Email is Required"),
  firstName: string().required("First Name is Required"),
  lastName: string().required("Last Name is Required"),
  phoneNumber: string().required("Phone Number is Required")
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(1, 'Min 1 digit')
  .max(15, 'Not more than 15 digits'),
  shippingAddress1: string().required("Shipping Address Line 1 is Required"),
  shippingAddress2: string(),
  city: string().required("City is Required"),
  state: string().required("State is Required"),
  zipCode: string().required("Zip Code is Required"),
  country: string().required("Country is Required"),
  serviceType:string().required("Service Type is Required"),
  mode:string()
});

