import {SINGLEPAGECATEGORIES}from '../constants/categoriesDetailData';
import {SUBLEVELVIDEOS}from '../constants/index';
export const isSinglePageCategory = (category: string) => {
  return (
    SINGLEPAGECATEGORIES.filter(
      (item) => item.toLowerCase() === category.toLowerCase()
    ).length > 0
  );
};
export const points = (data: any) => {
  let total_risk_value = 0;
  let total_points = 0
  if (data && data.sub_level) {
    Object.entries(data.sub_level).forEach(([key, value]: any) => {
      total_risk_value += value.total_risk_value;
      total_points+=value.total
    });
  }

  return `${((total_risk_value/total_points) * 100)}`;
};
export const pointsColor = (data: any, title:any) => {
  if(title !== "WORKOUT RESPONSE"){
    if(data <=25) return "text-[#07bc0c]"
    else if(data >25 && data <=39) return "text-[#f1c40f]"
    return "text-[#e74c3c]"
  }
  else{
    if(data <=49) return "text-[#000]"
    return "text-[#07bc0c]"
  }
}
export const pointsSubLevelColor = (data: any, title:any) => {
  if( title !== 'POWER/SPRINT' && title !== 'ENDURANCE' && title !== 'STRENGTH TRAINING' && title !=='MUFAS'){
    if(data <=25) return "text-[#07bc0c]"
    else if(data >25 && data <=39) return "text-[#f1c40f]"
    return "text-[#e74c3c]"
  }
  else{
    if(data <=49) return "text-[#000]"
    return "text-[#07bc0c]"
  }
}
export function findVideoLinkByName(nameToCheck: string) {
  const video = SUBLEVELVIDEOS.find((video) => video.name === nameToCheck);
  return video ? video.link : null;
}  