import React from "react";
import { Link } from "react-router-dom";
import arrow from "assets/Icons/arrow.png";
import { calculateTotalRiskSum,getSubCategoryLinks } from "utils";
type IProps = {
  data: { [key: string]: {} };
  category: string;
  title: string;
};

const AccordionItems = ({ data, category, title }: IProps) => {
  const totalRiskSum:any = parseFloat(calculateTotalRiskSum(data)).toFixed(0)
  const getPointsColor = () =>{
    if(title !== "WORKOUT RESPONSE"){
      if(totalRiskSum <=25) {
        return "text-[#07bc0c]"
      }
      else if(totalRiskSum >25 && totalRiskSum <=39) return "text-[#f1c40f]"
      return "text-[#e74c3c]"
    }
    else{
      if(totalRiskSum <=49) return "text-[#000]"
      return "text-[#07bc0c]"
    }
  }
  return (
    <Link to={`${getSubCategoryLinks(category, title)}`}>
      <div className="flex items-center border-t-2 border-field lg:px-14 py-2 px-3">
        <p className="text-[#4d4d4d] font-bold text-[14px] ">{title}</p>
        <span className={`${getPointsColor()} text-[18px] font-helvetica ml-4`}>
          {totalRiskSum}%
        </span>
        {/* <div>
          <img src={arrow} alt="arrow" className="w-8 h-8 ml-4" />
        </div> */}
      </div>
    </Link>
  );
};

export default AccordionItems;
