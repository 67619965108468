import React, { Fragment } from "react";
import { ReportData } from "state/records/types";
import CollapseItem from ".././Collapse/index";

type IProps = {
  data: ReportData;
};

const Accordion = ({ data }: IProps) => {
  return (
    <Fragment>
      {Object.entries(data).map(([key, value], index) => (
        <CollapseItem
          key2={key}
          key={key}
          index={index}
          value={value}
          data={data}
        />
      ))}
    </Fragment>
  );
};

export default Accordion;
