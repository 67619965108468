import React from "react";
import Approute from "./component/Approute";
import Authroute from "./component/Authroute";
import MainNavbar from "components/Header";
import { useUser } from "state/user/hooks";
import { useLocation } from "react-router-dom";
const MyRoutes = () => {
  const { isAuthenticated, user } = useUser();
  const location = useLocation()
  return (
    <div
      className={`root ${
        "bg-main"
      } h-screen`}
    >
      <MainNavbar isLoggedIn={isAuthenticated} user={user} />
      {isAuthenticated || location.pathname === '/register' || location.pathname === '/lab-reports-upload' ? <Approute isAdmin={!user?.isClient} /> : <Authroute />}
    </div>
  );
};

export default MyRoutes;
