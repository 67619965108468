import { useParams } from "react-router-dom";
import ReportTable from "../../components/ReportTable";
import { useClientsManager } from "hooks/useClientManager";
import { useEffect, useMemo } from "react";
import DataLoaderOverlay from "components/DataLoaderOverlay";
import * as moment from "moment-timezone";

const ClientDetail = () => {
  const { clientID } = useParams();
  const {
    fetchClientReports,
    isLoading,
    clientDetails,
    selectedClient,
    setSelectedClient,
  } = useClientsManager();
  useEffect(() => {
    fetchClientReports(clientID!);
  }, [fetchClientReports, clientID]);
  const tableData = useMemo(() => {
    return clientDetails.map((client) => {
      return {
        col1: moment.default(client.created).format("MMMM DD, YYYY"),
        col2:client.entry_no
      };
    });
  }, [clientDetails]);
  return (
    <DataLoaderOverlay isLoading={isLoading}>
      <div className="flex flex-col justify-center pt-14">
        <div className="px-[40px] w-full">
          <ReportTable
            data={tableData}
            showUpdateColumn={true}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
          />
        </div>
      </div>
    </DataLoaderOverlay>
  );
};

export default ClientDetail;
