import user from "state/user";
import {SUBLEVELVIDEOS } from "constants/index";

type ArgType = {
  [key: string]: {};
};
export const calculateTotalPoints = (
  data: ArgType,
  isSubCategory?: boolean
) => {
  let totalPoints = 0;
  if (isSubCategory) {
    for (let [, value] of Object.entries(data)) {
      //@ts-ignore
      totalPoints += value.total_risk_value;
    }
    return totalPoints;
  } else {
    for (let [, value] of Object.entries(data)) {
      for (let [, _value] of Object.entries(value)) {
        //@ts-ignore
        totalPoints += _value.total_risk_value;
      }
    }
    return totalPoints;
  }
};

export const calculateTotalRiskSum = (data: any) => {
  let totalSum = 0;
  let total = 0
  for (const categoryKey of Object.keys(data.sub_level)) {
    totalSum += data.sub_level[categoryKey].total_risk_value;
    total+=data.sub_level[categoryKey].total
  }
  return `${((totalSum/total) * 100)}`;
};

export const TotalRiskCalculator = (data:any ,key2:any) => {
    let totalRisk = 0;
    let total_points = 0
    for (const key1 of Object.keys(data[key2])) {
      const category = (data[key2])[key1];
      for (const key2 of Object.keys(category)) {
        const subCategoryData = category[key2];
        if (key2 === "sub_level") {
          for (const subLevelKey of Object.keys(subCategoryData)) {
            const riskData = subCategoryData[subLevelKey];
            totalRisk += riskData["total_risk_value"];
            total_points+=riskData.total
          }
        }
        }   
    }
    return `${((totalRisk/total_points) * 100)}`;
  };
export const getSubCategoryLinks = (category:string, title:string)=>{
  if(category === 'SLEEP') return `SLEEP/ONSET`
  if(category === 'STRESS RESPONSE') return `STRESS%20RESPONSE/CORTISOL`
  if(category === 'HORMONES') return `HORMONES/THYROID`
  return `${category}/${title}`
}


